<template>
  <div class="page-container">
    <page-header></page-header>
    <div class="dashboard-container">
      <div class="page-card">
        <div class="page-card-body" >
          <div class="descriptions" style="padding: 20px;">
            <div class="page-info-title">{{ $route.meta.title }}</div>
            <div class="descriptions-header">
              <div class="descriptions-title">基础信息</div>
            </div>
            <div class="descriptions-view">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">姓名</span>
                      <span class="descriptions-item-content">{{ metadata && metadata.name }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">电话号码</span>
                      <span class="descriptions-item-content">{{ metadata && metadata.phone }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">身份证号</span>
                      <span class="descriptions-item-content">
                        {{ metadata && metadata.idNo }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="descriptions" style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">发起合同</div>
            </div>
            <div class="descriptions-view">
              <div>
                <el-form :model="pageForm"
                         :rules="pageFormRules"
                         ref="formRef"
                         label-position="top"
                         label-width="120px">
                  <el-row>
                    <el-col :span="5">
                      <el-form-item label="签订方式" prop="签订方式">
                        <el-radio v-model="signingMode" label="ONLINE">线上签约</el-radio>
                        <el-radio v-model="signingMode" label="OFFLINE">线下签约</el-radio>
                      </el-form-item>
                    </el-col>
                    <el-col :span="5" :offset="1">

                    </el-col>
                    <el-col :span="5" :offset="1">

                    </el-col>
                    <el-col :span="5" :offset="1">

                    </el-col>
                  </el-row>
                  <el-row v-if="signingMode === 'ONLINE'">
                    <el-col :span="5">
                      <el-form-item label="合同类型" prop="consumeType" required
                                    :rules="{ required: this.signingMode === 'ONLINE', message: '请选择合同类型' }">
                        <el-select v-model="pageForm.consumeType" clearable>
                          <el-option v-for="iter in CONTRACT_TYPE"
                                     :key="iter.label"
                                     :label="iter.label"
                                     :value="iter.value"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="5" :offset="1">
                      <el-form-item label="合同模板" prop="templateId" required
                                    :rules="{ required: this.signingMode === 'ONLINE', message: '请选择合同模板' }">
                        <el-select v-model="pageForm.templateId" clearable @change="templateChange">
                          <el-option v-for="iter in contractTemplateRegion"
                                     :key="iter.templateId"
                                     :label="iter.templateName"
                                     :value="iter.templateId"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="5" :offset="1">

                    </el-col>
                    <el-col :span="5" :offset="1">

                    </el-col>
                  </el-row>
                  <!-- 合同字段 -->
                  <div v-if="currContractTemplate.components && signingMode === 'ONLINE'">
                    <el-form :model="contractField"
                             :rules="contractFieldRules"
                             ref="contractFieldRef">
                      <el-row v-for="(i, ii) in cuttingComponents()" :key="ii">
                        <el-col v-for="(iter, index) in i"
                                :key="iter.componentName"
                                :span="5" :offset="index % 4 !== 0 ? 1 : 0">
                          <el-form-item :label="iter.componentDescription"
                                        :prop="iter.componentName"
                                        :required="iter.componentRequired"
                                        :rules="generateVerification(iter)">
                            <!-- 输入字段 -->
                            <el-input v-if="iter.componentType === 'TEXT'"
                                      v-model="contractField[iter.componentName]"
                                      style="width: 100%;"></el-input>
                            <!-- 多行文本 -->
                            <el-input v-if="iter.componentType === 'MULTI_LINE_TEXT'"
                                      v-model="contractField[iter.componentName]"
                                      type="textarea"
                                      style="width: 100%;"></el-input>
                            <!-- 下拉选择 -->
                            <el-select v-if="iter.componentType === 'SELECT'"
                                       v-model="contractField[iter.componentName]"
                                       style="width: 100%;"
                                       clearable>
                              <el-option v-for="item in iter.options"
                                         :key="item.value"
                                         :label="item.name"
                                         :value="item.value"></el-option>
                            </el-select>
                            <!-- 单选 -->
                            <el-radio-group v-if="iter.componentType === 'RADIO'"
                                            v-model="contractField[iter.componentName]">
                              <el-radio v-for="item in iter.options"
                                        :key="item.name"
                                        :label="item.value">{{ item.name }}</el-radio>
                            </el-radio-group>
                            <!-- 勾选 -->
                            <el-checkbox v-if="iter.componentType === 'CHECK'"
                                         v-model="contractField[iter.componentName]"></el-checkbox>
                            <!-- 多选 -->
                            <el-checkbox-group v-if="iter.componentType === 'CHECK_BOX'"
                                               v-model="contractField[iter.componentName]"
                                               :data-aa="refresh" @change="refresh++">
                              <el-checkbox v-for="item in iter.options" :key="item.name" :label="item.value">{{item.name}}</el-checkbox>
                            </el-checkbox-group>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-form>
                  </div>

                  <el-row v-if="signingMode === 'OFFLINE'">
                    <el-col :span="12">
                      <el-form-item prop="files" required label="合同图片"
                                    :rules=" { required: this.signingMode === 'OFFLINE', message: '请上传合同照片' }">
                        <el-upload accept="image/*"
                                   class="upload-demo"
                                   action=""
                                   ref="filesImgUpload"
                                   list-type="picture-card"
                                   :file-list="imageList"
                                   :on-remove="onRemoveHandler"
                                   :http-request="handlerUploadImage">
                          <i class="el-icon-plus"></i>
                        </el-upload>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>

          <div class="descriptions" style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">操作</div>
            </div>
            <div class="descriptions-view" style="display: flex;justify-content: flex-end;">
              <el-button @click="closePage">取消</el-button>
              <el-button type="primary" @click="submit(false)">提交</el-button>
            </div>
          </div>

        </div>
      </div>
    </div>
    <el-dialog title="微信扫码签署合作协议"
               destroy-on-close
               :visible.sync="signUrlShow"
               width="30%">
      <div style="padding: 10px;display: flex;justify-content: center;">
        <el-image style="width: 300px;height: 300px;"
                  :src="signUrl"></el-image>
      </div>
      <div style="padding-top: 20px;text-align:center;">
        <div>企业已经静默完成签署</div>
        <div>请用户扫描二维码签约</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import {
  fileUpload,
  getProjectUserById,
  previewEsign,
  projectOffline,
  saveEsign,
  templateAllById,
  templateList
} from "@/api/apis";
import {exportPicture, viewImages} from "@/utils/common";
import helper, {gridConnectionStatusMapper, statusMapper} from "@/utils/helper";
import {CONTRACT_TYPE, INTENTION_CONSUMPTION_STATE, PIC_URL} from "@/utils/config";
export default {
  name: "Info",
  components: {PageHeader},
  data(){
    return {
      getData: getProjectUserById,
      id: '',
      metadata: {},

      farmer: {},
      workOrder: null,
      roofTypeModel: null,
      user: null,
      designData: {},
      INTENTION_CONSUMPTION_STATE,
      CONTRACT_TYPE,
      designDataFiles:[],

      // 线下签约
      pageForm: {
        // 线下签约 合同照片
        files: [],
        // 线上签约
        // 合同类型
        consumeType: '',
        // 合同模板
        templateId: '',
      },
      pageFormRules: {

      },
      contractFieldRules: {

      },
      // 签约模式 线上签约-ONLINE 线下签约-OFFLINE
      signingMode: 'ONLINE',
      imageList: [],
      contractTemplateRegion: [],
      currContractTemplate: {},
      contractField: {},
      //
      signUrl: '',
      signUrlShow: false,
      refresh: 0,
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.getInfo()
  },
  methods: {
    gridConnectionStatusMapper,
    viewImages,
    statusMapper,
    /**
     * 切割表单显示
     * n * 4
     */
    cuttingComponents(){
      const arrCutting = (arr) => {
        const chunkSize = 4
        const result = []

        for(let i = 0; i < arr.length; i += chunkSize) {
          result.push(arr.slice(i, i + chunkSize))
        }

        return result
      }

      return arrCutting(this.currContractTemplate.components)
    },
    /**
     * 生成验证
     * @param iter
     */
    generateVerification(iter){
      if (iter.componentType === 'TEXT') {
        return {
          required: iter.componentRequired,
          message: `请填写`
        }
      }
      if (iter.componentType === 'CHECK_BOX') {
        return [
          {
            required: iter.componentRequired,
            message: ' '
          },
          {
            validator: (rule, value, callback) => {
              if (this.contractField[iter.componentName].length < 1) {
                callback(new Error('至少选择一个选项'))
              } else {
                callback()
              }
            },
          }
        ]
      }
      return {
        required: iter.componentRequired,
        message: '请选择'
      }
    },
    /**
     *
     */
    closePage(){
      this.$router.replace(`/admin/contract`)
    },
    /**
     * 是否预览
     * @param preview
     */
    submit(preview = false){
      // 线下签约
      const offlineSigningHandler = () => {
        this.$refs.formRef.validate((valid) => {
          if (!valid) {
            return false
          }

          const params = {
            files: this.pageForm.files.join(','),
            projectId: this.$route.query.projectId
          }

          const loading = this.$loading({ fullscreen: true })
          projectOffline(params).then(() => {
            this.$message.success('提交成功')
            setTimeout(() => {
              this.closePage()
            }, 500)
          }).finally(() => {
            loading.close()
          })
        })
      }
      // 线上签约
      const validatorContractHandler = () => {
        this.$refs.formRef.validate((valid) => {
          if (!valid) {
            return false
          }
          //
          this.$refs.contractFieldRef.validate((val) => {
            if (!val) {
              return false
            }

            // 参数拼接
            const params = {
              templateId: this.currContractTemplate.templateId,
              formFields: [],
            }
            for (let key in this.contractField) {
              let val
              if (Array.isArray(this.contractField[key])) {
                val = `[${this.contractField[key].toString()}]`
              } else {
                val = String(this.contractField[key])
              }
              params.formFields.push({
                componentId: key,
                componentValue: val
              })
            }

            // 创建合同
            const loading = this.$loading({ fullscreen: true })
            if (preview) {
              previewEsign(this.$route.query.projectId, params).then(res => {
                console.log(res, '------res--');
              })
            } else {
              saveEsign(this.$route.query.projectId, this.pageForm.consumeType, params).then(res => {
                this.$message.success('提交成功')
                setTimeout(() => {
                  this.signUrl = PIC_URL + '/code?code=' + res
                  this.signUrlShow = true
                }, 500)
              }).finally(() => {
                loading.close()
              })
            }
          })
        })
      }

      switch (this.signingMode) {
        case 'ONLINE':
          validatorContractHandler()
          break
        case 'OFFLINE':
          offlineSigningHandler()
          break
      }
    },
    /**
     * 选择合同模板
     * @param e
     */
    templateChange(e){
      const item = this.contractTemplateRegion.find(iter => iter.templateId === e)
      if (item) {
        this.contractField = {}
        templateAllById(e).then(res => {
          // 生成表单字段
          const currContractTemplate = res
          // 排序 多行文本排最后 components
          const multipleLines = []
          const other = []
          for (let component of currContractTemplate.components) {
            if (component.componentType === 'MULTI_LINE_TEXT') {
              multipleLines.push(component)
            } else {
              if (component.componentType === 'CHECK_BOX'){
                this.contractField[component.componentName] = []
              }
              other.push(component)
            }
          }
          currContractTemplate.components = [...other, ...multipleLines]

          this.currContractTemplate = currContractTemplate
        })
      }
    },
    /**
     * 图片上传
     * @param e
     */
    handlerUploadImage(e){
      const loading = this.$loading({ fullscreen: true })
      fileUpload(e.file).then(res => {
        const name = ((res || '').split('/') || []).reverse()[0]
        this.imageList.push({
          name: name,
          url: helper.getSourceUri(res),
          value: res
        })
        this.pageForm.files.push(res)
      }).finally(() => {
        loading.close()
      })

      this.$refs.filesImgUpload.uploadFiles = this.$refs.filesImgUpload.uploadFiles.filter(iter => iter.status === 'success')
    },
    /**
     * @param f
     * @param fl
     */
    onRemoveHandler(f, fl){
      this.imageList = fl
      this.pageForm.files = []
      this.imageList.forEach(iter => {
        if (iter.status === 'success') {
          this.pageForm.files.push(iter.url)
        }
      })
    },
    /**
     * 点击文件
     * @param file
     */
    onPreviewHandler(file){
      const { url, name } = file
      exportPicture(url, name)
    },
    /**
     *
     */
    showFileList(item){
      const filesStr = item?.files || ''
      const files = []
      filesStr.split(',').forEach(iter => {
        const name = ((iter || '').split('/') || []).reverse()[0]
        iter && files.push({
          name: name,
          url: helper.getSourceUri(iter),
          value: iter
        })
      })
      return files
    },
    /**
     * 获取数据
     */
    getInfo(){
      //
      templateList().then(res => {
        this.contractTemplateRegion = res
      })
      // 用户信息
      if (this.id) {
        this.getData(this.id).then(res => {
          this.metadata = res
          //
          this.farmer = this.metadata?.farmer
          this.workOrder = this.metadata?.workOrder
          this.roofTypeModel = this.metadata?.workOrder?.roofTypeModel
          this.user = this.metadata?.workOrder?.user
          this.designData = this.metadata?.designData

          this.designDataFiles = this.showFileList(this.metadata)

        })
      } else {
        this.$message.error('页面异常, 请返回重新进入')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>